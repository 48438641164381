// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	LOGO,
	ROUTES,
	NAVIGATION_ITEMS,
	USER_NAVIGATION_ITEMS,
	DEFAULT_ROUTE,
	POSITIONS,
	THEMES,
	TITLES,
	VISUALS,
} from '@constants';

// Imports => Molecules
import AcPrivateRoute from '@molecules/ac-private-route/ac-private-route.web';
import AcToasterHoc from '@molecules/ac-toaster-hoc/ac-toaster-hoc.web';
import AcModal from '@molecules/ac-modal/ac-modal.web';
import AcModalContactForm from '@molecules/ac-modal-contact-form/ac-modal-contact-form.web';
import AcModalKeyboardShortcuts from '@molecules/ac-modal-keyboard-shortcuts/ac-modal-keyboard-shortcuts.web';

// Imports => Components
import AcHeader from '@components/ac-header/ac-header.web';
import AcNavigation from '@components/ac-navigation/ac-navigation.web';
import AcUserNavigation from '@components/ac-user-navigation/ac-user-navigation.web';
import AcActivityMonitor from '@components/ac-activity-monitor/ac-activity-monitor.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcLogo from '@atoms/ac-logo/ac-logo.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import AcErrorBoundary from '@atoms/ac-error-boundary/ac-error-boundary.web';
import AcAnnouncement from '@atoms/ac-announcement/ac-announcement.web';

const _CLASSES = {
	MAIN: 'ac-main',
	FOOTER: 'ac-footer',
	ROUTE: {
		TRANSITION: 'ac-route__transition',
		SECTION: 'ac-route__section',
	},
	TRANSITIONGROUP: 'ac-route__transition-group',
	TRANSITIONGROUPHIDDEN: 'ac-route__transition-group--hidden',
	PAGETRANSITION: 'ac-route__transition',
	ROUTESECTION: 'ac-route__section',
	MASK: {
		MAIN: 'ac-route__transition-mask',
		ENTER: 'ac-route__transition-mask--animating-enter',
		EXIT: 'ac-route__transition-mask--animating-exit',
	},
	LOADER: 'ac-route__loader',
};

let timeout = {
	enter: false,
	entered: false,
	exit: false,
	exited: false,
};

@inject('store')
@observer
class App extends React.Component {
	constructor(props) {
		super(props);

		this.store = props.store;
		this.route = props.location.pathname;

		this.$scroller = React.createRef();

		this.state = {
			onEndListener: false,
			animation: {
				mountOnEnter: true,
				unmountOnExit: true,
				appear: true,
				enter: false,
				exit: false,
				duration: 333,
				delay: 400,
			},
		};

		this.init = this.init.bind(this);
		this.handleRouteChanged = this.handleRouteChanged.bind(this);
		this.hideUIElement = this.hideUIElement.bind(this);

		this.handleEnterState = this.handleEnterState.bind(this);
		this.handleEnteredState = this.handleEnteredState.bind(this);
		this.handleExitState = this.handleExitState.bind(this);
		this.handleExitedState = this.handleExitedState.bind(this);
	}

	componentDidUpdate(nextProps, prevProps) {
		if (this.props.location.pathname !== this.route) {
			this.route = this.props.location.pathname;
			this.handleRouteChanged();
			if (this.store.auth.is_authorized) this.init();
		}
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		this.removeEvents();
	}

	async init() {
		if (this.store.auth.is_authorized) {
			await this.store.announcements.all().catch(error => {
				if (error && error.response && error.response.status === 401) {
					this.store.auth.logout();
				}
			});
			await this.store.profile.who_am_i();
			this.addEvents();
		}
	}

	addEvents = () => {
		document.addEventListener('keyup', this.handleKeyUp, false);
	};

	removeEvents = () => {
		document.removeEventListener('keyup', this.handleKeyUp, false);
	};

	handleKeyUp = event => {
		const key = event.key || event.which;
		const shift = event.Shift;
		const $active_element = document.activeElement;

		const $inputs = ['input', 'select', 'textarea'];

		if (
			$active_element &&
			$inputs.indexOf($active_element.tagName.toLowerCase()) !== -1
		) {
			return;
		}

		if (key) {
			if (key === '?' || (key === 191 && shift)) {
				this.showKeyboardShortcuts();
			}
		}
	};

	handleRouteChanged(event) {
		this.hideUIElement();
	}

	showKeyboardShortcuts = async () => {
		const { t } = this.props;
		await this.store.ui.reset(KEYS.MODAL);
		await this.store.ui.set(KEYS.MODAL, {
			title: t(TITLES.KEYBOARD_SHORTCUTS),
			body: <AcModalKeyboardShortcuts role={this.store.auth.current_role} />,
			closeable: true,
			actions: [],
			callback: () => {
				this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		});
		this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, true);
	};

	showContactForm = async () => {
		const { t } = this.props;
		await this.store.ui.reset(KEYS.MODAL);
		await this.store.ui.set(KEYS.MODAL, {
			title: t(TITLES.CONTACT_FORM),
			body: <AcModalContactForm />,
			closeable: true,
			actions: [],
			callback: () => {
				this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		});
		this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, true);
	};

	handleEnterState() {
		if (timeout.entered) clearTimeout(timeout.entered);
		if (timeout.exit) clearTimeout(timeout.exit);
		if (timeout.exited) clearTimeout(timeout.exited);

		this.setState({
			animation: {
				...this.state.animation,
				appear: false,
				enter: true,
				exit: false,
			},
		});
	}

	handleEnteredState() {
		timeout.entered = setTimeout(() => {
			this.setState({
				animation: {
					...this.state.animation,
					appear: false,
					enter: false,
					exit: false,
				},
			});
		}, this.state.animation.delay);
	}

	handleExitState(node, done) {
		if (timeout.entered) clearTimeout(timeout.entered);
		if (timeout.exited) clearTimeout(timeout.exited);
		timeout.exit = setTimeout(() => {
			this.setState({
				animation: {
					...this.state.animation,
					appear: false,
					enter: false,
					exit: true,
				},
			});

			if (done) done();
		}, this.state.animation.delay);
	}

	handleExitedState() {
		if (timeout.exit) clearTimeout(timeout.exit);
		timeout.exited = setTimeout(() => {
			this.setState({
				animation: {
					...this.state.animation,
					appear: false,
					enter: false,
					exit: false,
				},
			});
		}, this.state.animation.delay);
	}

	hideUIElement(event) {
		this.store.ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, false);
		this.store.ui.setValue(KEYS.USER_NAVIGATION, KEYS.VISIBLE, false);
		this.store.ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	}

	getLoaderClassNames() {
		return clsx(_CLASSES.LOADER);
	}

	getMaskClassNames() {
		const { animation } = this.state;

		return clsx([
			_CLASSES.MASK.MAIN,
			animation && (animation.enter || animation.appear) && _CLASSES.MASK.ENTER,
			animation && animation.exit && _CLASSES.MASK.EXIT,
		]);
	}

	getTransitionClassNames() {
		return clsx(_CLASSES.PAGETRANSITION);
	}

	getTransitionGroupClassNames() {
		const { ui } = this.store;

		const hidden = ui.navigation.visible;

		return clsx(_CLASSES.TRANSITIONGROUP, hidden && _CLASSES.TRANSITIONGROUPHIDDEN);
	}

	getRouteSectionClassNames() {
		return clsx(_CLASSES.ROUTESECTION);
	}

	getFooterClassNames() {
		return clsx(_CLASSES.FOOTER);
	}

	getMainClassNames() {
		return clsx(_CLASSES.MAIN);
	}

	getYear = () => {
		const today = new Date();

		return today.getFullYear();
	};

	render() {
		const { location, store } = this.props;
		const { latest, seen } = this.store.announcements;
		const { animation } = this.state;

		return (
			<AcErrorBoundary>
				<div className={this.getMainClassNames()}>
					{store.auth.is_authorized && (
						<>
							<AcHeader>
								<AcLogo
									visual={LOGO}
									callback={event => {
										const { push } = this.props.history;
										if (push) push(DEFAULT_ROUTE.path);
									}}
								/>
								<AcNavigation routes={NAVIGATION_ITEMS} />
								<AcUserNavigation
									key={location.key}
									routes={USER_NAVIGATION_ITEMS}
									onContactForm={this.showContactForm}
								/>
							</AcHeader>

							<footer className={this.getFooterClassNames()}>
								<AcContainer>
									<AcRow>
										<AcColumn>
											<div className={'h-text--align-center'}>
												Ziemann Portal <br />
												<small>
													&copy; {this.getYear()} Ziemann Cashservice Nederland
												</small>
											</div>
										</AcColumn>
									</AcRow>
								</AcContainer>
							</footer>
						</>
					)}

					<TransitionGroup
						className={this.getTransitionGroupClassNames()}
						onClick={this.hideUIElement}
					>
						<CSSTransition
							key={location.key}
							timeout={animation.duration}
							onEnter={this.handleEnterState}
							onEntered={this.handleEnteredState}
							onExit={this.handleExitState}
							onExited={this.handleExitedState}
							mountOnEnter={animation.mountOnEnter}
							unmountOnExit={animation.unmountOnExit}
							appear={animation.appear}
							clsx={_CLASSES.PAGETRANSITION}
						>
							<section
								className={this.getRouteSectionClassNames()}
								id={KEYS.SCROLLER}
								ref={this.$scroller}
							>
								<Switch location={location}>
									{ROUTES &&
										Object.keys(ROUTES)
											.filter(route => {
												if (!ROUTES[route].forbidden) return true;
												else if (!ROUTES[route].roles) return true;
												return (
													ROUTES[route].roles.indexOf(store.auth.current_role) > -1
												);
											})
											.filter(route => {
												if (!ROUTES[route].permissions) return true;
												else if (
													ROUTES[route].permissions &&
													store.profile.current_permissions.indexOf(
														ROUTES[route].permissions
													) > -1
												) {
													return true;
												}

												return false;
											})
											.map(route => (
												<AcPrivateRoute
													key={`route-${ROUTES[route].id}`}
													name={ROUTES[route].name}
													path={ROUTES[route].path}
													component={ROUTES[route].component}
													forbidden={ROUTES[route].forbidden}
													authorized={store.auth.is_authorized}
													exact
												/>
											))}
									{DEFAULT_ROUTE && (
										<AcPrivateRoute
											component={DEFAULT_ROUTE.component}
											forbidden={DEFAULT_ROUTE.forbidden}
										/>
									)}
								</Switch>
							</section>
						</CSSTransition>
					</TransitionGroup>

					<span className={this.getMaskClassNames()}>
						<AcLoader
							theme={THEMES.ALPHA}
							loading={this.state.animation.enter}
							visual={VISUALS.LOADER}
							className={this.getLoaderClassNames()}
						/>
					</span>

					{this.store.auth.is_authorized && latest && seen !== latest.id && (
						<AcAnnouncement
							{...latest}
							position={POSITIONS.TOP}
							onClose={this.store.announcements.close}
						/>
					)}

					<AcModal {...store.ui.modal}>{store.ui.modal.body}</AcModal>

					<AcToasterHoc
						queue={this.store.toasters.queue}
						callback={this.store.toasters.remove}
					/>

					<AcActivityMonitor />
				</div>
			</AcErrorBoundary>
		);
	}
}

export default withNamespaces()(withRouter(App));
